import {
  GetOrganisationsDocument,
  useDeleteUserMutation,
} from "@/graphql/types";
import gql from "graphql-tag";
import useParentQuery from "./useParentQuery";

gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export default function (organizationId: string, userId: string) {
  const deleteUserMutation = useDeleteUserMutation({});

  async function call() {
    deleteUserMutation.mutate(
      { userId: userId },
      {
        refetchQueries: [{ query: GetOrganisationsDocument }],
        update: (cache) => {
          const parentQuery = useParentQuery(organizationId);
          const cachedData = cache.readQuery(parentQuery);

          cache.writeQuery({
            ...parentQuery,
            data: {
              organization: {
                ...cachedData?.organization,
                users: {
                  count: cachedData?.organization?.users?.count
                    ? cachedData?.organization.users.count - 1
                    : 0,
                  items:
                    cachedData?.organization?.users?.items?.filter(
                      (element) => element?.userId != userId
                    ) ?? [],
                },
              },
            },
          });
        },
      }
    );
  }

  return {
    ...deleteUserMutation,
    call,
  };
}
