import {
  GetOrganizationDocument,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";

export default function (
  organizationId: string
): QueryOptions<GetOrganizationQueryVariables, GetOrganizationQuery> {
  return {
    query: GetOrganizationDocument,
    variables: { organizationId: organizationId },
  };
}
